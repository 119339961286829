import React from 'react';
import {
    StyledContainer,
    StyledContent,
    StyledContentContainer,
    StyledDeleteImage,
    StyledEditImage,
    StyledNameContainer,
    AbsoluteContainer,
    MarginContainer,
    StyledAlignContainer,
    StyledEditBar,
    StyledEditIcon
} from './styles';
import { NameIcon } from '../../atoms';
import { DeleteIcon, NewEditIcon } from '../../../assets/img';
import { IMemberCard } from './types';
import { Typography } from '@loophealth/loop-ui-web-library';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { DO_NOT_DELETE_MEMBERS, LIVE_IN_PARTNER_COMPANY_ID } from '../../../utils/constants/CompanyConstants';

const MemberCard: React.FunctionComponent<IMemberCard> = ({
    firstName,
    lastName,
    relationship,
    doB,
    gender,
    mobile,
    onMemberDelete,
    onMemberEdit,
    color
}) => {
    const companyId = useSelector((state: ReduxState) => state.user?.userData?.data?.employer || '');
    const isLiveIn = LIVE_IN_PARTNER_COMPANY_ID.includes(companyId);

    const isDeleteHidden = DO_NOT_DELETE_MEMBERS.includes(companyId);

    return (
        <StyledContainer data-automationid={`member-card-${relationship}`}>
            <AbsoluteContainer>
                <StyledEditImage onClick={onMemberEdit} src={NewEditIcon} />
                { relationship?.toLowerCase() !== 'self' && !isDeleteHidden && (
                    <StyledDeleteImage onClick={onMemberDelete} src={DeleteIcon} />
                )}
            </AbsoluteContainer>
            <StyledNameContainer>
                <MarginContainer>
                    <NameIcon firstName={firstName} lastName={lastName} color={color} />
                </MarginContainer>
                <StyledContent>
                    <Typography variant="extraSmall" weight="semiBold" color="secondary">
                        Name
                    </Typography>
                    <Typography variant="small" weight="semiBold">
                        {firstName + ' ' + lastName}
                    </Typography>
                </StyledContent>
            </StyledNameContainer>
            <StyledContentContainer>
                <StyledAlignContainer>
                    <StyledContent>
                        <Typography variant="extraSmall" weight="semiBold" color="secondary">
                            Relationship
                        </Typography>
                        <Typography variant="small" weight="semiBold">
                            {isLiveIn && relationship =='spouse' ? 'spouse/partner': relationship}
                        </Typography>
                    </StyledContent>
                    <StyledContent>
                        <Typography variant="extraSmall" weight="semiBold" color="secondary">
                            DOB
                        </Typography>
                        <Typography variant="small" weight="semiBold">
                            {doB}
                        </Typography>
                    </StyledContent>
                </StyledAlignContainer>
                <StyledAlignContainer>
                    <StyledContent>
                        <Typography variant="extraSmall" weight="semiBold" color="secondary">
                            Gender
                        </Typography>
                        <Typography variant="small" weight="semiBold">
                            {gender}
                        </Typography>
                    </StyledContent>
                    <StyledContent>
                        <Typography variant="extraSmall" weight="semiBold" color="secondary">
                            Mobile
                        </Typography>
                        <Typography variant="small" weight="semiBold">
                            {mobile}
                        </Typography>
                    </StyledContent>
                </StyledAlignContainer>
            </StyledContentContainer>
            <StyledEditBar onClick={onMemberEdit}>
                <Typography variant="small" weight="semiBold">
                    Edit
                </Typography>
                <StyledEditIcon src={NewEditIcon} />
            </StyledEditBar>
        </StyledContainer>
    );
};

export default MemberCard;
