import { IPolicyDetails, IPreValidation } from '../../../redux/slices/EnrolmentSlice/types';
import { LIVE_IN_PARTNER_COMPANY_ID } from '../../../utils/constants/CompanyConstants';
import { IMappings } from '../../../utils/mapping';
import { IGetDependentCountReturnType, IGetRelationshipReturnType, IParentOptions } from './types';

export const oneOfParentsOrInLawsOptions = ['Parents OR parents-in-law', 'Parents OR parent-in-laws'];

export const parentOptions: IParentOptions[] = [
    {
        id: 'parent',
        name: 'Insure Parents',
        value: 'Parent'
    },
    {
        id: 'parent-in-law',
        name: 'Insure Parents-In-Law',
        value: 'Parent-in-law'
    }
];

export const getCountByrelationUtil = (
    arr: {
        relation: string;
        number: number;
    }[] | null | undefined,
    r: string
): number => arr?.find((d) =>d.relation.toLowerCase() === r)?.number ?? 0;

export const getRelationshipUtil = (category: string, companyId?: string): IGetRelationshipReturnType => {
    const children = ['1 Kid', '2 Kids', '3 Kids', '4 Kids'];
    const parent = ['Parents', 'Parents Only'];
    if (category === 'Spouse') {
        if (companyId && LIVE_IN_PARTNER_COMPANY_ID.includes(companyId)) {
            return 'Spouse/Partner';
        }
        return category;
    } else if (children.includes(category)) return 'child';
    else if (parent.includes(category)) return 'parent';
    else if (category === 'Parents (including in-laws)') return 'parent/parent-in-law';
    else if (oneOfParentsOrInLawsOptions.includes(category)) return 'parent|parent-in-law';
    else if (category === 'Parents-in-law') return 'parent-in-law';
    else return '';
};

export const getDependentCountUtil = (
    mappingType: 'target' | 'current',
    relationship: string,
    currentMapping: IMappings | null,
    targetMapping: IMappings | null,
    selectedParentId: string
): IGetDependentCountReturnType => {
    let number = 0;
    if (mappingType === 'target') {
        number = getCountByrelationUtil(targetMapping?.dependents, relationship.toLowerCase());
    } else {
        if (relationship.toLowerCase() === 'parent/parent-in-law') {
            number = getCountByrelationUtil(currentMapping?.dependents, 'parent') +
                    getCountByrelationUtil(currentMapping?.dependents, 'parent-in-law');
        } else if (relationship.toLowerCase() === 'parent|parent-in-law') {
            number = getCountByrelationUtil(currentMapping?.dependents, selectedParentId.toLowerCase());
        } else {
            number = getCountByrelationUtil(currentMapping?.dependents, relationship.toLowerCase());
        }
    }
    return { relation: relationship, number };
};

export const getRelationAgeUtil = (relation: string, preValidations: IPolicyDetails, title: string): string => {
    let calculatedString = '';
    const relationship = relation.toLowerCase();
    const fieldValidation: IPreValidation | undefined = preValidations.data?.find((relation) => {
        if (relation.hasOwnProperty(relationship)) {
            return relation;
        }
    });
    if (typeof fieldValidation !== 'undefined') {
        const dobValidation = Object.values(fieldValidation);
        if (dobValidation[0]?.dob) {
            calculatedString = `This policy allows insuring ${title} from 
        ${dobValidation[0]?.dob?.min.toString()} years to ${dobValidation[0]?.dob?.max.toString()} years`;
        }
    }
    return calculatedString;
};
