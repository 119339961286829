import React, { ChangeEvent } from 'react';
import { IFormErrorType, IGenderType, IManageMemberModal, IRelationship } from './types';
import { ChevronDownIcon } from '../../../assets/img';
import { useDispatch, useSelector } from 'react-redux';
import { resetCrudErrors } from '../../../redux/slices/EnrolmentSlice';
import { ReduxState } from '../../../redux';
import {
    StyledContainer,
    StyledInputGroup,
    StyledRow,
    StyledSelect,
    StyledSelectOption,
    StyledHeading,
    StyledSpan,
    StyledDividerContainer,
    StyledAlignment,
    StyledButtonContainer,
    StyledStickyContainer,
    StyledDisclaimerContainer
} from './styles';
import { Divider } from '../../atoms';
import { checkRelationship, getAllowedDependents } from './ManageMemberService';
import { handleDependentsUtil, handleExistingDependentPresentUtil, initialFormErrors, liveInOptions } from './utils';
import { addDependent, editMember, enrolSelectedUserToPolicy } from '../../../redux/slices/EnrolmentSlice/thunks';
import { useMobileNumberValidation } from '../../hooks/useMobileNumberValidation';
import { useNameValidation } from '../../hooks/useNameValidation';
import { IDependent } from '../../../redux/slices/PolicyListSlice/types';
import { Alerts, Form, LoopButton, Modal, Toast, Typography } from '@loophealth/loop-ui-web-library';
import { checkParentalPolicy } from '../../../utils/PolicyServices';
import { FirebaseTimestamp } from '../../../adapters/provider';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import useSegment from '../../../utils/hooks/useSegment';
import { LIVE_IN_PARTNER_COMPANY_ID } from '../../../utils/constants/CompanyConstants';

const ManageMemberModal: React.FunctionComponent<IManageMemberModal> = ({
    isVisible,
    setIsVisible,
    isEditMode = false,
    existingDependents,
    dependentId = '',
    selectedRelationship,
    selectedDependentRelation,
    ...formValues
}) => {
    const dispatch = useDispatch();
    const trackClick = useSegment('click');
    const loopToast = Toast.useToast();
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId || '');
    const selfEmployeeId = useSelector((state: ReduxState) => state.user?.userData?.data?.employeeId || '');
    const enrolmentDueDate = useSelector(
        (state: ReduxState) => state.enrolment.currentlySelectedPolicy?.enrolmentDueDate
    );
    const selfEmail = useSelector((state: ReduxState) => state.user?.userData?.data?.email || '');
    const loading = useSelector((state: ReduxState) => state.enrolment.crudDependents?.loading);
    const policyId = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy?.policyId);
    const companyId = useSelector((state: ReduxState) => state.user?.userData?.data?.employer);
    const isLiveInCompany = LIVE_IN_PARTNER_COMPANY_ID.includes(companyId) && selectedDependentRelation === 'Spouse';
    const dependentList = useSelector((state: ReduxState) => state.policyList?.allDependents);
    const error = useSelector((state: ReduxState) => state.enrolment.crudDependents.error);
    const { firstName, lastName, validateName, onChange } = useNameValidation(
        formValues.firstName,
        formValues.lastName
    );
    const { mobileNumber, setMobileNumber, validateMobileNumber, onMobileChange } = useMobileNumberValidation(
        formValues.mobileNumber
    );
    const [doB, setdoB] = React.useState<Date | undefined>(formValues.doB);
    const [formErrors, setFormErrors] = React.useState<IFormErrorType>(initialFormErrors);
    const [gender, setGender] = React.useState<IGenderType>(formValues.gender ? formValues.gender : '');
    const [existingDependent, setExistingDependent] = React.useState('');
    const [displayRelationOptions, setDisplayRelationOptions] = React.useState(true);
    const [displayFormFields, setDisplayFormFields] = React.useState(true);
    const [relationshipValues, setRelationshipValues] = React.useState([]);
    const availableGender = [
        { name: 'Male', value: 'male' },
        { name: 'Female', value: 'female' }
    ];
    const currentPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const [relationship, setRelationship] = React.useState<IRelationship>('');
    const allowedDependents = getAllowedDependents(dependentList, existingDependents);
    const [stateLoader] = React.useState<boolean>(false);
    interface ErrorObject {
        type?: string;
        message?: string;
    }
    // handle relationship dropdown population
    React.useEffect((): void => {
        if (isVisible) {
            const relationshipArray: Record<string, string>[] = [];
            if (selectedDependentRelation === 'Parents (including in-laws)') {
                relationshipArray.push(
                    { name: 'Parent', value: 'Parent' },
                    { name: 'Parent-in-law', value: 'Parent-in-law' }
                );
                setRelationshipValues(relationshipArray as any);
            } else {
                if (isLiveInCompany) {
                    setRelationshipValues(liveInOptions as any);
                } else {
                    relationshipArray.push({ name: selectedRelationship, value: selectedRelationship });
                    setRelationshipValues(relationshipArray as any);
                    setRelationship(selectedRelationship);
                }
            }
        }
    }, [selectedDependentRelation, selectedRelationship, isVisible]);

    // handle select dependent that is already added
    React.useEffect((): void => {
        if (selectedRelationship) {
            const hasDependent = handleDependentsUtil(selectedRelationship, allowedDependents);
            if (typeof hasDependent === 'undefined') {
                setDisplayRelationOptions(false);
                setDisplayFormFields(true);
            } else {
                setDisplayRelationOptions(true);
            }
        }
    }, [selectedRelationship, allowedDependents]);

    // below loads the data into forms when it is in edit mode
    React.useEffect(() => {
        if (isVisible) {
            if (isEditMode) {
                firstName.setValue(formValues.firstName || '');
                lastName.setValue(formValues.lastName || '');
                setMobileNumber(formValues.mobileNumber || '');
                formValues.doB && setdoB(new Date(formValues.doB));
                setGender(formValues.gender || '');
            } else {
                firstName.setValue('');
                lastName.setValue('');
                setMobileNumber('');
                setdoB(undefined);
                setGender('');
            }
        }
    }, [isVisible]);

    React.useEffect(() => {
        if (error?.message) {
            let errorObj: ErrorObject;
            try {
                errorObj = JSON.parse(error.message);
                console.log('errorObj --> ', errorObj);

                setFormErrors((prevErrors) => {
                    const newErrors = { ...prevErrors };

                    if (errorObj?.type === 'mobile') {
                        newErrors.mobileNumber = errorObj.message || '';
                    } else if (errorObj?.type === 'age') {
                        newErrors.dob = errorObj.message || '';
                    } else if (errorObj?.type === 'internal') {
                        newErrors.global = errorObj.message || '';
                    } else {
                        newErrors.global = 'An unknown error occurred';
                    }

                    return newErrors;
                });
            } catch (e) {
                console.error('Failed to parse error message as JSON:', e);
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    global: 'An unknown error occurred'
                }));
            }
        }
    }, [error]);

    React.useEffect(() => {
        if (formErrors.global && isVisible) {
            setIsVisible(false);
            loopToast?.error(`${formErrors.global}`, 'Technical Trouble on Our End!', {
                variant: 'dark'
            });
        }
    }, [formErrors]);

    const handleRelationSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setExistingDependent(e.target.value.trim() as any);
        e.target.value ? setDisplayFormFields(false) : setDisplayFormFields(true);
    };

    const saveUserDetails = (isEditMode: boolean) => {
        if (relationship === '' || !doB) return;
        return isEditMode
            ? editMember({
                dependent: {
                    doB: FirebaseTimestamp.fromDate(doB).seconds,
                    gender: gender,
                    mobile: mobileNumber,
                    relationship: `${relationship}`,
                    userId: dependentId,
                    firstName: firstName.value,
                    lastName: lastName.value
                },
                selfId: userId,
                policyId: policyId || '',
                companyId,
                selfEmail,
                enrolmentDueDate: enrolmentDueDate ? new Date(enrolmentDueDate * 1000) : new Date()
            })
            : addDependent({
                dependent: {
                    doB: FirebaseTimestamp.fromDate(doB).seconds,
                    gender: gender,
                    mobile: mobileNumber,
                    relationship: relationship,
                    firstName: firstName.value,
                    lastName: lastName.value
                },
                selfEmployeeId,
                policyId: policyId || '',
                companyId,
                selfId: userId
            });
    };

    const validateForm = async () => {
        dispatch(resetCrudErrors());

        const segmentAction = SEGMENT_ACTIONS.CLICK.SAVE_DETAILS_MEMBER_CARD_CONFIRM;
        trackClick({
            name: segmentAction.name,
            properties: {
                modal_type:
                    (segmentAction.properties?.modal_type as (depId: string) => string)(isEditMode ? 'Edit' : 'Add') ||
                    '',
                relation_type:
                    (segmentAction.properties?.relation_type as (relation: string) => string)(selectedRelationship) ||
                    '',
                login_status: segmentAction.properties?.login_status,
                page_title: segmentAction.properties?.page_title
            }
        });
        // ;
        // handle add existing dependent to the policy
        if (existingDependent) {
            const selectedDependent: IDependent | undefined = handleExistingDependentPresentUtil(
                dependentList,
                existingDependent
            );
            if (selectedDependent) {
                dispatch(
                    enrolSelectedUserToPolicy({
                        selfId: userId,
                        userId: selectedDependent.userId,
                        policyId: policyId || '',
                        dependentDetails: selectedDependent,
                        companyId
                    })
                );
            }
        } else {
            // handle form validations and errors set
            const { firstNameError, lastNameError } = validateName();
            const mobileNumberError = validateMobileNumber();
            const genderError = gender ? '' : 'Please select a valid gender';
            const relationshipError = relationship ? '' : 'Please select a valid relationship';
            const dobError = doB ? '' : 'Please select a valid Date';
            const updatedErrors = {
                mobileNumber: mobileNumberError,
                firstName: firstNameError,
                lastName: lastNameError,
                gender: genderError,
                relationship: relationshipError,
                global: '',
                dob: dobError
            };
            setFormErrors(updatedErrors);

            // save details only if no errors are recorded for any of the form fields
            if (Object.values(updatedErrors).every((err) => err === '')) {
                dispatch(saveUserDetails(isEditMode));
                return;
            }
        }
    };

    const handleCloseModal = (isVisible: boolean) => {
        setIsVisible(isVisible);
        setFormErrors(initialFormErrors);
    };

    return (
        <Modal
            title={isEditMode ? 'Edit ' + selectedRelationship : 'Add ' + selectedRelationship}
            isVisible={isVisible}
            setIsVisible={handleCloseModal}
            disablePadding={true}
            overflow="visible"
        >
            <StyledContainer>
                {isLiveInCompany && (
                    <StyledDisclaimerContainer>
                        <Alerts.TextAlert
                            variant="warning"
                            // eslint-disable-next-line max-len
                            title="You may add a live-in partner to your insurance policy. To do so, simply add them by adding their details in the relationship field."
                            closable={false}
                        />
                    </StyledDisclaimerContainer>
                )}
                {displayRelationOptions && currentPolicy && checkParentalPolicy(currentPolicy) && (
                    <>
                        <StyledRow>
                            <StyledSelectOption>
                                <StyledHeading>Select a previously added member</StyledHeading>
                                <StyledSelect
                                    $error={false}
                                    $color={existingDependent === ''}
                                    $src={ChevronDownIcon}
                                    name="existingDependent"
                                    value={existingDependent}
                                    onChange={handleRelationSelectChange}
                                >
                                    <option value="">Select</option>
                                    {allowedDependents?.map((dependent, index) => {
                                        if (checkRelationship(selectedRelationship, dependent)) {
                                            return (
                                                <option key={index} value={dependent.userId}>
                                                    {dependent.firstName + ' ' + dependent.lastName}
                                                </option>
                                            );
                                        }
                                    })}
                                </StyledSelect>
                            </StyledSelectOption>
                        </StyledRow>
                        {displayFormFields ? (
                            <>
                                <StyledDividerContainer>
                                    <Divider>OR</Divider>
                                </StyledDividerContainer>
                                <StyledRow>
                                    <StyledHeading>Add a new member</StyledHeading>
                                </StyledRow>
                            </>
                        ) : null}
                    </>
                )}
                {displayFormFields ? (
                    <>
                        <StyledRow $isError={!!formErrors.firstName || !!formErrors.lastName}>
                            <StyledInputGroup data-automationid="first-name-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="semiBold">
                                        First Name
                                    </Typography>
                                    <StyledSpan>*</StyledSpan>
                                </StyledAlignment>
                                <Form.Input
                                    placeholder="Enter First Name"
                                    onChange={(e) => onChange(e, 'firstName')}
                                    value={firstName.value}
                                    error={formErrors.firstName}
                                    type="text"
                                />
                            </StyledInputGroup>
                            <StyledInputGroup data-automationid="last-name-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="semiBold">
                                        Last Name
                                    </Typography>
                                    <StyledSpan>*</StyledSpan>
                                </StyledAlignment>
                                <Form.Input
                                    placeholder="Enter Last Name"
                                    onChange={(e) => onChange(e, 'lastName')}
                                    value={lastName.value}
                                    error={formErrors.lastName}
                                    type="text"
                                />
                            </StyledInputGroup>
                        </StyledRow>
                        <StyledRow $isError={!!formErrors.relationship || !!formErrors.dob}>
                            <StyledInputGroup data-automationid="relationship-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="semiBold">
                                        Relationship
                                    </Typography>
                                    <StyledSpan>*</StyledSpan>
                                </StyledAlignment>
                                <Form.Dropdown
                                    placeholder="Select Relationship"
                                    listItems={relationshipValues}
                                    onClick={(e: string) => setRelationship(e.trim() as IRelationship)}
                                    selectedItem={relationship}
                                    isDisabled={relationshipValues.length <= 1 ? true : false}
                                    error={formErrors.relationship}
                                />
                            </StyledInputGroup>
                            {/* IF ELSE */}

                            <StyledInputGroup data-automationid="dob-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="semiBold">
                                        Date of Birth (DD/MM/YYYY)
                                    </Typography>
                                    <StyledSpan>*</StyledSpan>
                                </StyledAlignment>
                                <Form.DatePicker
                                    value={doB || null}
                                    onChange={setdoB}
                                    maxDate={new Date()}
                                    placeholder="Enter DOB"
                                    iconOrder="left"
                                    size="medium"
                                    fontSize="small"
                                    error={formErrors.dob}
                                />
                            </StyledInputGroup>
                        </StyledRow>
                        <StyledRow $isError={!!formErrors.gender || !!formErrors.mobileNumber}>
                            <StyledInputGroup data-automationid="gender-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="semiBold">
                                        Gender
                                    </Typography>
                                    <StyledSpan>*</StyledSpan>
                                </StyledAlignment>
                                <Form.Dropdown
                                    placeholder="Select Gender"
                                    listItems={availableGender}
                                    onClick={(e: string) => setGender(e.trim() as IGenderType)}
                                    selectedItem={gender}
                                    error={formErrors.gender}
                                />
                            </StyledInputGroup>
                            <StyledInputGroup data-automationid="mobile-number-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="semiBold">
                                        Mobile Number (Optional)
                                    </Typography>
                                </StyledAlignment>
                                <Form.Input
                                    placeholder="+91 | Enter phone number"
                                    onChange={(e) => onMobileChange(e)}
                                    value={mobileNumber}
                                    error={formErrors.mobileNumber}
                                />
                            </StyledInputGroup>
                        </StyledRow>
                    </>
                ) : null}
            </StyledContainer>

            <StyledStickyContainer>
                <StyledButtonContainer>
                    <LoopButton variant="filled" onClick={validateForm} isLoading={loading || stateLoader}>
                        Save Details
                    </LoopButton>
                </StyledButtonContainer>
            </StyledStickyContainer>
        </Modal>
    );
};

export default ManageMemberModal;
